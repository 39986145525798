import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import sitelogo from '../images/gc-logo.png';

class Header extends Component {
    
    render() {
      return (
        <header>
            <div id="site-header">
                <div id="site-logo">
                    <img src={sitelogo} alt="Gifted Cards"/>
                </div>
                <div id="site-cover">
                    <div className="glow">Gifted Cards</div>
                </div>
                <div id="site-spacer"></div>
                <div id="site-menu">
                    <ul className="nav">
                        <li className="menu-button"><div className="li-inner"><div className="li-mid"><Link to="/">Home</Link></div></div></li>
                        <li className="menu-button"><div className="li-inner"><div className="li-mid"><a href="https://www.numonday.com/shop/giftedcards/">Shop</a></div></div></li>
                    </ul>
                </div>
                
            </div>
            
        </header>
      );
    }
  }
   
export default Header;