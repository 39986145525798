import React, { Component } from 'react';

import {Link} from 'react-router-dom';
class Footer extends Component {
  render() {
    return (
      <div>
        <footer>
           <div id="site-footer">
                <div id="footer-inner">
                    <div id="footer-menu"><Link to="/">Home</Link>  |  <a href="https://www.numonday.com/shop/giftedcards/">Shop</a>  |  <Link to="/privacy">Privacy</Link>  |  <Link to="/information">Information</Link></div>
                    <div id="footer-copyright">&copy; <a href="https://www.ameliaraine.me.uk">msrainea</a> 2024</div>
                </div>
            </div>
        </footer>
      </div>        
    );
  }
}
 
export default Footer;