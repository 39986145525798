import React from 'react';
import {Routes, Route} from 'react-router-dom';
import './styles/gcstyles.css';
import Header from './constants/header.js';
import Footer from './constants/footer.js';
import Home from './pages/home';
import Information from './pages/information';
import Privacy from './pages/privacy';

function App() {
  return (
    <div className="App" id="main">
      <Header />
      <main>
        <div id="site-content">
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/information" element={<Information />} />
            <Route path="/about" element={<Information />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path='/shop' component={() => { 
                window.location.href = 'https://www.numonday.com/shop/giftedcards/'; 
                return null;
            }}/>
          </Routes>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;
